import React from 'react'
import ComposePage from 'src/components/Page/Author/Compose'



const ComposePageContainer = ({ location }) => {
    return <ComposePage location={location} />
}

export default ComposePageContainer

